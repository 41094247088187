<template>
    <div class="body">
        <h1>广告点位与停车场综合管理平台</h1>
        <div class="login-form">
            <div class="head-info">
                <!-- <label class="lbl-1"> </label>
                <label class="lbl-2"> </label>
                <label class="lbl-3"> </label> -->
            </div>
            <div class="clear"> </div>
            <div class="avtar">
                <img src="../assets/image/avtar.png">
				
            </div>
            <el-form @keyup.enter.native="submitForm">
                <input type="text" class="text" v-model="formLogin.user_login_id" placeholder="用户名" >
                <div class="key"><input type="password" class="password" v-model="formLogin.user_pwd" placeholder="密码"></div>
				<div class="verify">
					<input type="text"  class="text1" v-model="formLogin.verify_code" placeholder="请输入验证码" >
					<img :src='verifycode' @click='refreshCode' style="cursor: pointer;">
				</div>
            </el-form>
            <div class="signin"><input type="submit" value="登录" @click="submitForm"></div>
        </div>
    </div>
</template>

<script>
import util from '../util/util'
import attr from '../../vue.config'
import guid from 'guid'


export default {
    data () {
        return {
            formLogin: {
                user_login_id: '',
                user_pwd: '',
				verify_code:''
            },
            isphone: false,
            screenWidth: 0,
            remenber: false,
            rules: {
                user_login_id: [{ required: true, message: '请输入用户名', trigger: 'blur' }]
            },
			verifycode:'',

			uuid:''
        }
  },
  created () {
	  this.uuid=guid.raw()
  },
  mounted () {
    this.remenber = false
    var item= window.localStorage.getItem('ca_login_info')
    if (item !== null&&item!==undefined) {
      this.remenber = true
      this.formLogin=JSON.parse(item)
    }
	this.getVerifyCode()
  },
  methods:{
    submitForm () {
        if(!this.formLogin.user_login_id){
            this.$message.error('登录账号禁止为空')
            return
        }
		this.formLogin.uid=this.uuid
        util.Post('Login/loginauth', this.formLogin).then(res => {
            if (res.rpStatus === 10000) {
              	window.sessionStorage.setItem('ca_login_token', res.rpMsg)// token
              	window.sessionStorage.setItem('MeunItem', JSON.stringify(res.list[0].listMenu))
			  	window.sessionStorage.setItem('user_auth', JSON.stringify(res.list[0].listAuth))
              	this.$router.push('/home')
            } else {
              this.$message.error(res.rpMsg)
            }
        })
    },
	getVerifyCode(){
		this.verifycode = attr.devServer.proxy['/api'].target+"/login/Captcha?uid="+this.uuid+"&time=" + new Date()
	},
	refreshCode(){
		this.getVerifyCode()
	}
  }
}
</script>

<style>
.body{

	font-family: 'Open Sans', sans-serif;
	-webkit-background-size:cover;
	-moz-background-size:cover;
	-o-background-size:cover;
    width: 100%;
    height: 100%;
    margin:0;
    background: url("../assets/image/login.jpg") no-repeat 0px 0px;
    overflow: hidden;
    background-size: cover;
}
.wrap{
	margin: 0 auto;
	width: 80%;
}
body a,form li,input[type="submit"],input[type="text"],.sixth-login input[type="submit"],.third-login input[type="submit"]{
	transition: 0.1s all;
	-webkit-transition: 0.1s all;
	-moz-transition: 0.1s all;
	-o-transition: 0.1s all;
}
h1 {
	  font-family: 'Exo 2', sans-serif;
	  text-align: center;
	  padding-top: 4em;
	  font-weight: 700;
	  color: #2B2B36;
	  font-size: 2em;
}
.login-form {
	background: #2b2b36;
	position: relative;
	width: 30%;
	margin: 3% auto 0 auto;
	text-align: center;
	border-radius: 15px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	-o-border-radius: 15px;
}
.head img {
	width: 100%;
}
.avtar img {
  margin: 2em 0 0;
}
.head-info {
  padding: 5px 0;
  text-align: center;
  font-weight: 600;
  font-size: 2em;
  color: #fff;
  background: #23232e;
  height: 50px;
	border-top-left-radius: 10px;
	-webkit-border-top-left-radius: 10px;
	-moz-border-top-left-radius: 10px;
	-o-border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	-webkit-border-top-right-radius: 10px;
	-moz-border-top-right-radius: 10px;
	-o-border-top-right-radius: 10p
}
.verify{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}
.verify img{
	height: 30px;
	width: 150px;
	margin-bottom: 10px;
}
.text {
	  width: 70%;
	  padding: 1em 2em 1em 3em;
	  color: #9199aa;
	  font-size: 18px;
	  outline: none;
	  background: url(../assets/image/yonghu.png) no-repeat 10px 15px; 
	  border: none;
	  font-weight: 100;
	  border-bottom: 1px solid#484856;
	  margin-top: 2em;
}
.text1 {
	width: 30%;
	padding: 1em 2em 1em 3em;
  	color: #9199aa;
	font-size: 18px;
	outline: none;
	background: url(../assets/image/yzm.png) no-repeat 10px 15px; 
	border: none;
	font-weight: 100;
	border-bottom: 1px solid#484856;
	margin-bottom: 2em;
}



.password{
	  width: 70%;
	  padding: 1em 2em 1em 3em;
	  color: #dd3e3e;
	  font-size: 18px;
	  outline: none;
	  background: url(../assets/image/key.png) no-repeat 10px 23px;
	  border: none;
	  font-weight: 100;
	  border-bottom: 1px solid#484856;
 }
.key {
   /* background: url(../images/pass.png) no-repeat 447px 17px; */
}
input[type="submit"]{
  font-size: 30px;
  color: #fff;
  outline: none;
  border: none;
  background: #3ea751 ;
  width: 100%;
  padding: 18px 0;
  border-bottom-left-radius: 15px;
	-webkit-border-bottom-left-radius: 15px;
	-moz-border-bottom-left-radius: 15px;
	-o-border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	-webkit-border-bottom-right-radius: 15px;
	-moz-border-bottom-right-radius: 15px;
	-o-border-bottom-right-radius: 15px;
	cursor: pointer;
}
input[type="submit"]:hover {
	background: #53C488;
  border-bottom-left-radius: 15px;
	-webkit-border-bottom-left-radius: 15px;
	-moz-border-bottom-left-radius: 15px;
	-o-border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	-webkit-border-bottom-right-radius: 15px;
	-moz-border-bottom-right-radius: 15px;
	-o-border-bottom-right-radius: 15px;
  	transition: 1s all;
	-webkit-transition: 1s all;
	-moz-transition: 1s all;
	-o-transition: 1s all;
}
label.lbl-1 {
  background: #6756ea;
  width: 20px;
  height: 20px;
  display: block;
  float: right;
  border-radius: 50%;
  margin: 16px 10px 0px 0px;
}
label.lbl-2 {
  background: #ea569a;
  width: 20px;
  height: 20px;
  display: block;
  float: right;
  border-radius: 50%;
   margin: 16px 10px 0px 0px;
}
label.lbl-3 {
  background: #f1c85f;
  width: 20px;
  height: 20px;
  display: block;
  float: right;
  border-radius: 50%;
  margin: 16px 10px 0px 0px;
}
/*--copyrights--*/
.copy-rights{
	text-align: center;
	margin-top: 8em;
}
.copy-rights p{
	color:#FFF;
	font-size: 1em;
	line-height:1.8em;
}
.copy-rights p a{
	color:#ff2a75;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	text-decoration:none;
}
.copy-rights p a:hover{
	color:#3faa53;
	text-decoration:none;
		transition: 0.1s all;
	-webkit-transition: 0.1s all;
	-moz-transition: 0.1s all;
	-o-transition: 0.1s all;
}

@media (max-width:1440px){
	.key {
	  /* background: url(../images/pass.png) no-repeat 376px 17px; */
	}
	
	body {
	  min-height: 811px;
	}
}
@media (max-width:1366px){
	.key {
	  /* background: url(../images/pass.png) no-repeat 358px 19px; */
	}
	.copy-rights {
	  margin-top: 3em;
	}
	body {
	  min-height: 768px;
	}
}
@media (max-width:1280px){
	.key {
	   /* background: url(../images/pass.png) no-repeat 336px 18px; */
	}
	body {
	  min-height: 711px;
	}
	.copy-rights {
	  margin-top: 0.5em;
	}
	.verify img{
		height: 30px;
		width: 150px;
	}
}
@media (max-width:1024px){
	.login-form {
	  width: 37%;
	}
	.key {
	   /* background: url(../images/pass.png) no-repeat 339px 18px; */
	}
	.copy-rights {
	  margin-top: 3em;
	}
	h1 {
	  padding-top: 2em;
	}
	body {
	  min-height: 675px;
	}
	.verify img{
		height: 30px;
		width: 100px;
	}
}
@media (max-width:768px){
	.login-form {
	  width: 50%;
	    margin: 12% auto 0 auto;
	}
	.key {
	  /* background: url(../images/pass.png) no-repeat 342px 18px; */
	}
	body {
	  min-height: 929px;
	}
	.verify img{
		height: 30px;
		width: 80px;
	}
}
@media (max-width:640px){
	.login-form {
	  width: 60%;
	  margin: 20% auto 0 auto;
	}
	.key {
	  /* background: url(../images/pass.png) no-repeat 342px 19px; */
	}
}
@media (max-width:480px){
	.login-form {
	  width: 80%;
	}
}
@media (max-width:320px){
	h1 {
	  padding-top: 1em;
	  font-size: 1.5em;
	}
	.login-form {
	  width: 90%;
	  margin: 10% auto 0 auto;
	}
	input[type="text"] {
	  width: 62%;
	  padding: 1.2em 2em .5em 2.5em;
	  font-size: 17px;
	  margin-top: .5em;
	}
	input[type="password"] {
		width: 62%;
		padding: 1.2em 2em .5em 2.5em;
		font-size: 17px;
		margin-top: .5em;
		margin-bottom: 2em;
		  /* background: url(../images/key.png) no-repeat 8px 23px; */
	}
	.key {
	  /* background: url(../images/pass.png) no-repeat 235px 27px; */
	}
	.avtar img {
	  margin: 1.1em 0 0;
	}
	.verify img{
		width: 50px;
	}

	.head-info {
	  height: 35px;
	  }
	label.lbl-1 {
	  margin: 8px 10px 0px 0px;
	}
	label.lbl-2 {
	  margin: 8px 10px 0px 0px;
	}
	label.lbl-3 {
	  margin: 8px 10px 0px 0px;
	}
	.close {
	  left: 16px;
	  top: 13px;
	}
	.copy-rights {
	  margin-top: 2em;
	}
	body {
	    min-height: 504px;
	}
	input[type="submit"] {
	  font-size: 28px;
	  padding: 10px 0;
	}
}
</style>